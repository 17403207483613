import React, {useEffect, useLayoutEffect, useState} from 'react';

import {Flex, Box} from 'rebass';
import {useSpring, animated} from 'react-spring';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';

import {fadeGray} from '@renofi/utils';
import {slow2} from '@renofi/utils/src/animation';

import MessageInput from '../MessageInput';
import MessageSubmit from '../MessageSubmit';
import stream from '../streamer';
import Message from '../Message';
import events from '../events';

import {ChatMessages, Content} from './ChatContainer.styled';
import Timestamp from './Timestamp';
import BackupMessages from './BackupMessages/BackupMessages';

const ChatContainer = ({id, onNewMessage, history, timestamp, greetings}) => {
  const [value, setValue] = useState('');
  const [loader, setLoader] = useState(false);

  const [showTimestamp, setShowTimestamp] = useState(false);
  const [response, setResponse] = useState('');
  const spring = useSpring({
    from: {opacity: 0, transform: 'scale(0.7, 0)'},
    to: {opacity: 1, transform: 'scale(1, 1)'},
    config: slow2,
  });
  const hasBotMessages = history?.filter(
    (item) => item.role === 'assistant',
  ).length;

  useEffect(() => {
    setTimeout(() => {
      setShowTimestamp(true);
      scrollToBottom();
    }, 100);
  }, []);

  useEffect(() => {
    scrollToBottom({animated: true});
  }, [history?.length]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [response]);

  function onChange(e) {
    setValue(e.target?.value);
    events.onUserTyping();
  }

  function scrollToBottom({animated} = {}) {
    const element = document.getElementById('renochat-scroll-end');
    element?.scrollIntoView({
      behavior: animated ? 'smooth' : 'instant',
      block: 'end',
      inline: 'end',
    });
  }

  function onSubmit() {
    scrollToBottom({animated: true});
    setShowTimestamp(false);
    const message = {
      role: 'user',
      content: value,
      ...(window.LogRocket?.sessionURL
        ? {logrocket_session_url: window.LogRocket.sessionURL}
        : {}),
    };
    onNewMessage({...message, id: 'temp'});

    setValue('');
    setLoader(true);

    stream({
      id,
      messages: isEmpty(greetings) ? [message] : [greetings, message],
      onChange: (rsp) => {
        setLoader(false);
        setResponse((state) => `${state} ${rsp}`);
        scrollToBottom();
      },
      onSuccess: (chunks) => {
        onNewMessage({role: 'assistant', content: chunks.join('')});
        setResponse('');
        setShowTimestamp(true);
        scrollToBottom();
        setLoader(false);
        events.onUserMessage();
      },
    });
  }

  return (
    <animated.div
      style={{
        ...spring,
        transformOrigin: 'bottom right',
        width: '100%',
        height: '100%',
      }}>
      <Content>
        <Flex flexDirection="column" width="100%" height="100%">
          <ChatMessages>
            {!hasBotMessages ? (
              <BackupMessages
                left
                dark={false}
                botIcon
                animate={false}
                shadow={false}
              />
            ) : null}

            {history?.map(({id, role, content, timestamp}) => (
              <Message
                id={id}
                key={id}
                left={role === 'assistant'}
                dark={role === 'user'}
                timestamp={timestamp}
                animate={role === 'user'}>
                {content}
              </Message>
            ))}
            {response ? (
              <Message left id={id} animate={false}>
                {response}
              </Message>
            ) : null}

            {loader ? (
              <Message left id={id} animate={false} loading>
                thinking ...
              </Message>
            ) : null}

            {hasBotMessages ? (
              <Timestamp show={showTimestamp} timestamp={timestamp} />
            ) : null}

            <Box id="renochat-scroll-end" height={1} />
          </ChatMessages>

          <Flex css={{borderTop: `solid 1px ${fadeGray}`}}>
            <MessageInput
              onChange={onChange}
              value={value}
              onEnterPress={onSubmit}
            />
            {value ? <MessageSubmit onClick={onSubmit} /> : null}
          </Flex>
        </Flex>
      </Content>
    </animated.div>
  );
};

ChatContainer.propTypes = {
  id: PropTypes.string,
  history: PropTypes.array,
  onNewMessage: PropTypes.func.isRequired,
  timestamp: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  greetings: PropTypes.object,
};

export default ChatContainer;
