import {useEffect, useState} from 'react';

import {Flex} from 'rebass';
import {v4 as uuidv4} from 'uuid';

import {CloseIcon, Toggle} from '@renofi/components';
import {
  getDomain,
  mediaQuery,
  setItem,
  useLocalStorage,
  useScreenSize,
} from '@renofi/utils';
import {LEAD_SESSION_DAYS} from '@renofi/utils/src/lead';
import {CHAT_ID_COOKIE_KEY} from '@renofi/utils/src/const';

import ToggleButton from '../ToggleButton';
import ChatContainer from '../ChatContainer';
import Message from '../Message';
import {fetchHistory} from '../streamer';
import BackupMessages from '../ChatContainer/BackupMessages/BackupMessages';
import events from '../events';

import {Invitation, Close} from './App.styled';

const INIT_DELAY = 2000;

function App() {
  const {isMobile} = useScreenSize();
  const [history, setHistory] = useState([]);
  const [greetings, setGreetings] = useState({});
  const [status, setStatus] = useState('idle');
  const [id, setId] = useLocalStorage(CHAT_ID_COOKIE_KEY);
  const [timestamp, setTimestamp] = useLocalStorage('renofi_chat_timestamp');
  const isFullScreen = status === 'open' && isMobile;

  useEffect(() => {
    if (!id) {
      const uuid = uuidv4();
      setId(uuid);
      // also set in shared cookie for POS and dashboard
      setItem({
        key: CHAT_ID_COOKIE_KEY,
        value: uuid,
        numberOfDays: LEAD_SESSION_DAYS,
        domain: getDomain(),
      });
    }
  }, [id]);

  useEffect(() => {
    let timeout;

    if (status === 'open') {
      setTimestamp(new Date());
    }

    if (status === 'idle' && history?.length <= 1 && !timestamp) {
      timeout = setTimeout(() => {
        setStatus('invite');
      }, INIT_DELAY);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [status, history?.length]);

  useEffect(() => {
    if (!id) return;

    (async () => {
      const rsp = await fetchHistory({id});

      const isGreetingsOnly = rsp?.messages?.length === 1;
      if (isGreetingsOnly) setGreetings(rsp.messages[0]);

      setHistory(rsp?.messages || []);
    })();
  }, [id]);

  function addMessage({role, content}) {
    setGreetings({});
    setHistory((state = []) => [
      ...state,
      {
        role,
        content,
        id: uuidv4(),
      },
    ]);
    setTimestamp(new Date());
  }

  function openChat() {
    setStatus('open');
    events.onOpen();
  }
  function closeChat() {
    setStatus('idle');
    events.onClose();
  }

  return (
    <Flex
      className={`renochat renochat-${status}`}
      alignItems="flex-end"
      justifyContent="flex-end"
      css={mediaQuery({
        minWidth: 60,
        minHeight: 60,
        maxHeight: ['100%', 768],
        position: 'relative',
        width: ['100%', 'auto'],
        height: status === 'open' ? '100%' : status === 'invite' ? 220 : 60,
      })}>
      <Flex
        justifyContent="flex-end"
        css={mediaQuery({
          padding: [0, 16],
          width: ['100%', 'auto'],
        })}
        height="100%">
        <Toggle show={status === 'open'}>
          <Toggle show={isFullScreen}>
            <Close onClick={closeChat}>
              <CloseIcon />
            </Close>
          </Toggle>
          <ChatContainer
            greetings={greetings}
            timestamp={timestamp}
            id={id}
            onNewMessage={addMessage}
            history={history}
          />
        </Toggle>

        <Toggle show={status === 'invite'}>
          <Invitation onClick={openChat}>
            {greetings ? (
              <Message
                shadow
                dark={false}
                animate
                bgColor="white"
                left
                delayIndex={1}
                botIcon={false}>
                {greetings.content}
              </Message>
            ) : null}
            {!history?.length ? (
              <BackupMessages
                shadow
                bgColor="white"
                animate
                dark={false}
                botIcon={false}
                left={false}
              />
            ) : null}
          </Invitation>
        </Toggle>

        {!isFullScreen ? (
          <ToggleButton status={status} onOpen={openChat} onClose={closeChat} />
        ) : null}
      </Flex>
    </Flex>
  );
}

export default App;
